import { IUseSupplierCatalogsFilter, IUseSupplierCatalogsFilterParams } from 'models/composables/useSupplierCatalogsFilter.model';
import { IBaseFilterProps, IFilterEnum, IFilterEnumProps } from 'shared/models/filters.model';
import { debounce } from 'lodash-es';
import Loader from 'shared/utils/loaderHelper.util';
import { PimCatalogService } from 'services/api/pimCatalogApi.service';
import { EProductFilterId } from 'enums/productFilterId.enum';
import { FilterUtils } from 'shared/utils/filterHelper.util';

export function useSupplierCatalogsFilter(params = {} as ComputedRef<IUseSupplierCatalogsFilterParams>): IUseSupplierCatalogsFilter {
  const catalogId = ref<number | undefined>();
  const notFoundModels = ref(false);

  const searching = Loader.getReactiveInstance();

  const loadingText = 'Ищем поставщика...';

  const emptyText = computed(() => (notFoundModels.value ? `В этой категории поставщик #${catalogId.value} не найден` : undefined));

  async function search(searchCatalogId: number, filter: IFilterEnum) {
    try {
      if (!searchCatalogId) {
        return;
      }

      const paramsValues = params.value;

      const catalogHasModels = await PimCatalogService.isCatalogHasModels({
        catalogId: searchCatalogId,
        categoryId: paramsValues?.categoryId,
        clientId: paramsValues?.clientId,
        basisId: paramsValues?.basisId,
        fiasId: paramsValues?.fiasId,
        okeiCode: paramsValues?.okeiCode,
        ociMode: paramsValues?.ociMode,
        filter: paramsValues?.filterItems?.value?.length ? FilterUtils.makeQueryObjectString(paramsValues.filterItems.value) : undefined,
      });

      if (!catalogHasModels) {
        notFoundModels.value = true;
        return;
      }

      filter.options = [
        ...filter.options,
        {
          id: Number(searchCatalogId),
          name: `#${searchCatalogId}`,
          checked: false,
        },
      ];
    } catch (error) {
      notFoundModels.value = true;
    } finally {
      searching.deactivate();
    }
  }

  const debounceSearch = debounce(search, 500);

  async function onSearchEnumOptions(searchText: string, filter: IFilterEnum): Promise<void> {
    notFoundModels.value = false;

    if (!searchText || isCatalogIdExistInOptions(searchText, filter)) {
      debounceSearch.cancel();
      searching.deactivate();
      catalogId.value = undefined;
      return;
    }

    searching.activate();
    catalogId.value = Number(searchText);

    await debounceSearch(catalogId.value, filter);
  }

  function isCatalogIdExistInOptions(catalogId: string, filter: IFilterEnum): boolean {
    return filter.options?.some((option) => option.id === Number(catalogId));
  }

  function getFilterBaseProps(filterId: string): IBaseFilterProps | undefined {
    return filterId === EProductFilterId.CatalogId
      ? ({
          onSearchEnumOptions,
          loadingText: loadingText,
          emptyText: emptyText.value,
          searching: searching.value,
          searchPlaceholder: 'Введите номер поставщика',
          searchClearable: true,
          searchIconDisabled: true,
        } as IFilterEnumProps)
      : undefined;
  }

  return {
    onSearchEnumOptions,
    getFilterBaseProps,
  };
}
